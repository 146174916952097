import React, { useState } from 'react';

const App: React.FC = () => {
  const [a, setA] = useState<number>(0);
  const [b, setB] = useState<number>(0);
  const [result, setResult] = useState<number | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const response = await fetch(`https://api.hsyspace.com/test/add?a=${a}&b=${b}`);
    const data = await response.json();
    setResult(data.sum);
  };

  return (
    <div>
      <h1>HSY SPACE</h1>
      <p>Hello, world!</p>
      <p>We are building a web forum for hsyers!</p>
      <p>If you want join us, please send an email to <a href='mailto:hello@hsyspace.com'>hello@hsyspace.com</a></p>
      <p>For technical issues, please contact <a href='mailto:xh@hsyspace.com'>xh@hsyspace.com</a></p>
      <footer>
        <p>Powered by Cloudflare, React and Nginx</p>
        <p>HSY Space Project Team</p>
      </footer>
    </div>
  );
};

export default App;